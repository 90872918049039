import React, { Fragment, Component } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavigationDrawer } from 'react-md';
import ParkhubLogo from './assets/parkhub-vip-logo-gray.svg';
import { Header, SessionDialog } from '@parkhub/parkhub-ui';
import { checkAuth, signOut, getCookie, getAuthUser } from './utils';
import AccessPage from './components/AccessPage/AccessPage';
import UsageTable from './components/UsageTable/UsageTable';
import NavItemLink from './components/NavItemLink/NavItemLink';
import './App.scss';

const navItems = [{
  label: 'Access',
  to: '/',
  exact: true,
  icon: 'vpn_key',
}, {
  label: 'Usage',
  to: '/usage',
  icon: 'data_usage',
}, {
  label: 'Docs',
  to:  'https://docs.parkhub.com',
  icon: 'library_books',
}];

const styles = {
  content: { minHeight: 'auto' },
};
class App extends Component {

  state = {
    error: null,
    sessionModalVisible: false
  };

  componentDidMount = () => {
    this.unlisten = this.props.history.listen(location => {
      this.onRouteChanged(location);
    });

    this.checkLoggedIn();
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({
      error: error
    });
  }

  componentWillUnmount() {
    this.unlisten();
    clearTimeout(this.timer);
  }

  checkLoggedIn = () => {
    // Uncomment this block to enable authentication
    if (!checkAuth()) {
      window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
    } else {
      this.startTimeout();
    }
  };


  onRouteChanged() {
    this.checkLoggedIn();
  }

  hide = () => {
    this.setState({ sessionModalVisible: false });
  }

  show = () => {
    this.setState({ sessionModalVisible: true });
  }

  startTimeout = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.show, 29 * 60 * 1000); // 29 minutes
  }

  render() {
    return (
      <Fragment>
        <Header
          authUser={getCookie('86553b39') && getAuthUser()}
          signOut={() => signOut()}
          logoSrc={ParkhubLogo}
          logoWidth={239}
          logoClick={()=> this.props.history.push('/')}
        />
        <NavigationDrawer
          navItems={navItems.map(props => <NavItemLink {...props} key={props.to} />)}
          contentId="parkhub-content"
          contentStyle={styles.content}
          contentClassName="md-grid"
          navClassName="navigator"
        >
          <Switch>
            <Route exact path={navItems[0].to} render={() => <AccessPage {...this.props} />} />
            <Route exact path={navItems[1].to} render={() => <UsageTable {...this.props} />} />

          </Switch>
        </NavigationDrawer>

        <SessionDialog
          onHide={this.hide}
          visible={this.state.sessionModalVisible}
          onTimeExpire={() => signOut('?expired=true')}
          onContinue={this.startTimeout}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser
});

export default withRouter(
  connect(
    mapStateToProps,
    { checkAuth }
  )(App)
);
