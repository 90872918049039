import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import { FontIcon, ListItem } from 'react-md';

const style = {
  'borderRadius': '30px',
  'height': 'max-content',
  'padding': '5px',
  'marginTop': '10px',
  'marginLeft': '20px',
  'color': 'black'
};

/**
 * Due to the fact that react-router uses context and most of the components
 * in react-md use PureComponent, the matching won't work as expected since
 * the PureComponent will block the context updates. This is a simple wrapper
 * with Route to make sure that the active state is correctly applied after
 * an item has been clicked.
 */
const NavItemLink = ({ label, to, icon, exact }) => {

  return (
    <Route path={to} exact={exact}>
      {({ match }) => {
        let leftIcon;
        if (icon) {
          leftIcon = <FontIcon>{icon}</FontIcon>;
        }
        if (to.includes('http')) {
          return (
            <ListItem
              component={Link}
              to=""
              onClick={() => window.open(to, '_blank')}
              primaryText={label}
              style={style}
              leftIcon={leftIcon}
            />
          );
        }

        return (
          <ListItem
            component={Link}
            active={!!match}
            to={to}
            primaryText={label}
            primaryTextStyle={{ 'color':'rgba(0, 0, 0, 0.6)' }}
            onTouchEnd={_=>_}
            style={style}
            leftIcon={leftIcon}
          />
        );
      }}
    </Route>
  );
};


NavItemLink.propTypes = {
  exact: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
};
export default NavItemLink;
