import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getAuthUser } from '../../utils';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner.jsx';
import axios from 'axios';

const ajax = axios.create({
  headers: { 'Content-Type': 'application/json' }
});

const columns = [{
  name: 'Organization',
  selector: 'Organization',
  sortable: true,
},
{
  name: 'Total Calls',
  selector: 'Total Calls',
  sortable: true,
}];

const detailColumns = [{
  selector: 'Endpoint',
  wrap: true
},
{
  selector: 'Calls',
  wrap: true
}];
const ExpandableComponent = ({ data }) => {
  return (
    <DataTable
      style={{ width: '85%', marginLeft: 'auto' }}
      columns={detailColumns}
      data={data.Endpoints}
      noHeader
      noTableHead
      striped
      overflowY
      responsive
    />);
};

const stage = process.env.REACT_APP_ENV_TAG.toUpperCase();
const version = 'V2';

export default function UsageTable() {
  const [rows, setRows] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const cookie = getAuthUser();
    const fetchOrgData = async () => {
      setShowSpinner(true);
      const orgKeys = cookie.g.map(rel => rel.o);
      // fetches displayname and externalKey
      const orgs = await Promise.all(orgKeys.map(async (key) => {
        return await ajax.get(`${process.env.REACT_APP_CORE_USER_API_URL}/organizations/${key}`, { withCredentials: true });
      }));
      const keys = GetOrgData('externalKey', orgs);

      // fetch usage data from dynamodb
      const p = [];
      keys.forEach((value, key) => {
        const version = 'V2';
        p.push(
          ajax.get(`${process.env.REACT_APP_PUBLIC_API_DATA_URL}/${key}?version=${version}&stage=${stage}&from=1576423699`)
            .then(res => ({ key, usage: res.data }))
        );
      });
      const usage = await Promise.all(p);

      const r = usage.reduce((data, ele) => {
        if (Object.keys(ele.usage).length) {
          data.push({ 
            'Organization': keys.get(ele.key), 
            'Total Calls':ele.usage[`${stage}-COUNT`].TOTALCOUNT,
            'Endpoints': Object.keys(ele.usage[stage][version]).map((endpoint) => (
              { 'Endpoint': endpoint, 'Calls': ele.usage[stage][version][endpoint] }
            ))
          });
        }
        return data;
      }, []);
      setShowSpinner(false);
      setRows(r);
    };

    const GetOrgData = (key, object) => {
      return object.reduce((data, ele) => {
        data.set(ele.data[key], ele.data.displayName);
        return data;
      }, new Map());
    };
    fetchOrgData();
  },[]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <LoadingSpinner show={showSpinner} delay={200} />
      <DataTable
        columns={columns}
        data={rows}

        fixedHeader
        striped
        overflowY
        responsive
        expandableRows
        expandableRowDisabled={row => row.disabled}
        expandableRowsComponent={<ExpandableComponent />}
      />
    </div>
  );
}
