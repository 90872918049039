import React from 'react';
import { List, ListItem } from 'react-md';
import './RouteList.scss';

const RouteList = ({ routes }) => {
  return (
    <List>
      {listItems({ routes })}
    </List>
  );
};

function listItems({ routes }) {
  return routes.map(route => {
    return (
      <ListItem
        primaryText={<div>
          <span className={`method-${route.method.toLowerCase()}`}>{route.method}</span>
          <span className='route-path'>{route.path.toLowerCase().replace(/_/gi, '/')}</span>
        </div>
        }
        key={route.path+route.method}
      />);
  });
}

export default RouteList;
