/* eslint-disable max-len */
import './AccessPage.scss';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { TextField, SelectField } from 'react-md';
import { getAuthUser } from '../../utils';
import { RouteList } from '../';

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

const AccessPage = () => {

  const [allNames, setNames] = useState(['...loading']);
  const [orgData,  setOrgData] = useState(new Map());
  const [organizationKey, setExternalKey] = useState('');
  const [organizationAPIKey, setAPIKey] = useState('');
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const cookie = getAuthUser();
    const fetchOrgData = async () => {
      const orgKeys = cookie.g.map(rel => rel.o);
      // fetches displayname and externalKey
      const orgs = await Promise.all(orgKeys.map(async (key) => {
        return ajax.get(`organizations/${key}`);
      }));
      const apiKeys = await Promise.all(orgKeys.map(async (key) => {
        return ajax.get(`organizations/${key}/api-keys`);
      }));

      const names = GetOrgData('displayName', orgs);
      setNames([...names].sort(function (a, b) {
        var A = a.toUpperCase();
        var B = b.toUpperCase();
        if (A < B) {return -1;}
        if (A > B) {return 1;}
        return 0;
      }));

      // Searching all levels of orgs object find an associated api key if exists and merge into org data
      // return individual org object regardless of found api key and set in a map with displayName
      const apiData = apiKeys.map(keys => keys.data).flat();
      const orgInfo = orgs.reduce((data, ele) => {
        let keyassoc = apiData.find((d) => d.displayName === ele.data.displayName) || {};
        data.set(ele.data.displayName, Object.assign({}, keyassoc, ele.data, { relationships:[] }));
        keyassoc = apiData.find((d) => d.displayName === ele.data.displayName) || {};
        data.set(ele.data.displayName, Object.assign({}, keyassoc, ele.data));
        return data;
      }, new Map());
      setOrgData(orgInfo);
    };

    const GetOrgData = (key, object) => {
      return object.reduce((data, ele) => {
        data.add(ele.data[key]);
        return data;
      }, new Set());
    };

    const run = async () => {
      await Promise.all([
        fetchOrgData()
      ]);
    };

    run();
  }, []);

  const fetchRouteData = async (orgKey) => {
    try {
      const personRoutes = await ajax.get(`persons/routes?version=V2&externalKey=${orgKey}`);
      setRoutes(personRoutes.data.routes.sort(sortFunc));
    } catch (error) {
      setRoutes([]);
    }
  };

  async function onOrgChange(data) {
    const org = orgData.get(data);
    setExternalKey(org?.externalKey || '');
    setAPIKey(org?.publicAPIKey || '');
    await fetchRouteData(org?.externalKey);
  }

  function sortFunc(a, b) {
    var pathA = a.path.toUpperCase();
    var pathB = b.path.toUpperCase();
    if (pathA < pathB) {return -1;}
    if (pathA > pathB) {return 1;}
    return 0;
  }

  return (
    <>
      <div className="wrapper">
        <div className="row center-xl">
          <div className="col-xs-9">
            <div className="description">
              <h1>Access</h1>
              <p>
                All requests to the API, except for the /status and /ding endpoints, require both authentication and authorization.
              </p>
              <ul>
                <li>
                  Authentication takes the form of an API key which ParkHub distributes to partners and should be attached to each request in an x-api-key header.
                </li>
                <li>
                  Authorization uses the Basic Authentication scheme. It requires an Authorization header with the token a base64 encoded combination of your username and password. Mozilla reference on Basic Auth headers here.
                </li>
              </ul>
            </div>
            <div className={'md-grid'}>
              <SelectField
                id={'Organization-name'}
                className="r col-md-6"
                label="Organization Names"
                menuItems={allNames}
                onChange={onOrgChange}
              />
            </div>
            <div className={'md-grid'}>
              <TextField
                floating
                id="Organization-Key"
                label="Organization Key"
                className="first orgData col-xs-5 col-md-6"
                onChange={() => {}}
                value={organizationKey}
              />
              <TextField
                floating
                type="password"
                id="apiKey"
                label="apiKey"
                className="first orgData col-xs-5 col-md-6"
                onChange={() => {}}
                value={organizationAPIKey}
              />
            </div>
          </div>
          <div className="routelist col-sm-9">
            <div>You have access to the following endpoints:</div>
            <RouteList routes={routes} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessPage;
